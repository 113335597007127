import React from 'react';
import ListRow from '../../components/LoanTrackerComponents/ListRow';
import 'C:/Users/colel/source/repos/my-app/src/CSS/LoanTracker.css';

const paymentAmount1 = 500;
const paymentDate1 = new Date();

const paymentAmount2 = 285;
const paymentDate2 = new Date();

const paymentAmount3 = 1046;
const paymentDate3 = new Date();

const paymentAmount4 = 1987;
const paymentDate4 = new Date();

const LoansListPage: React.FC = () => {
    return (
        <>
    <div className="loan-list">
    <div className="surface-0 shadow-2 border-round">
    <div className="font-medium text-3xl text-900 mb-3 ml-3 mt-5">Current Loans</div>
    <ul className="list-none p-0 m-0">
        <ListRow 
            name='Cole Lenard'
            address='12345 N Fake Street'
            paymentAmount={paymentAmount1}
            paymentDueDate={paymentDate1}
            />
        <ListRow    
            name='Colleen Sullivan'
            address='8529 E Imaginary Rd'
            paymentAmount={paymentAmount2}
            paymentDueDate={paymentDate2}
            />
        <ListRow    
            name='Michael Sullivan'
            address='4578 W Made Up Ln'
            paymentAmount={paymentAmount3}
            paymentDueDate={paymentDate3}
            />
        <ListRow    
            name='Ahri'
            address='6851 S Nonexistant Way'
            paymentAmount={paymentAmount4}
            paymentDueDate={paymentDate4}
            />
    </ul>
    </div>
    </div>
</>
    );
};

export default LoansListPage;