import axios from 'axios';
import { coordinates } from '../types';

const baseUrl = 'https://geocode.maps.co/search?';

const key = '6688bfd2138fd242016740cvtdb6411'

export const getCoordinatesFromCity = async (city: string, state: string): Promise<coordinates> => {
    try {
        const query = `${city}+${state}+US`.replace(/\s+/g, '+');
        const response = await axios.get(baseUrl, {
            params: {
                q: query,
                api_key: key,
            },
        });

        if (response.data.length > 0) {
            const { lat, lon } = response.data[0];
            return { latitude: parseFloat(lat), longitude: parseFloat(lon) };
        } else {
            throw new Error('Location not found');
        }
    } catch (error) {
        console.error('Error fetching geocoding data', error);
        throw error;
    }
};