import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../CSS/WeatherApp.css';
import { forecastPeriod } from '../../types';

type TenDayForecastProps = {
  data: forecastPeriod[] | null;
};

const TenDayForecast: React.FC<TenDayForecastProps> = ({ data }) => {

  console.log('prop data: ', data);
  
  const imageBodyTemplate = (rowData: forecastPeriod) => {
    return <img src={rowData.imageUrl} alt={rowData.shortForecast} style={{ width: '50px' }} />;
  };

  const formatTemperature = (rowData: forecastPeriod) => {
    return `${rowData.temperature}°`;
  };

  return (
    <div>
      {data && (
        <DataTable value={data} stripedRows tableStyle={{ minWidth: '50rem' }}>
          <Column field="name" header="Name"></Column>
          <Column body={formatTemperature} header="Temperature"></Column>
          <Column body={imageBodyTemplate} header="Icon"></Column>
          <Column field="shortForecast" header="Weather Condition"></Column>
          <Column field="probabilityOfPrecipitation.value" header="Percent Chance of Rain"></Column>
        </DataTable>
      )}
    </div>
  );
};

export default TenDayForecast;