import axios from 'axios';

const baseUrl = 'https://api.weather.gov';

const getWeatherForecastURL = async (latitude: number, longitude: number) => {
    try {
      const response = await axios.get(`${baseUrl}/points/${latitude},${longitude}`);
      return response.data.properties.forecast;
    } catch (error) {
      console.error('Error fetching forecast URL', error);
      throw error;
    }
  };

const getWeatherForecast = async (url: string) => {
  //Doesn't need base url because it gets the entire api call passed in.
  try {
    const response = await axios.get (url);
    console.log('forecast data: ', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching weather data', error);
    throw error;
  }
};

const getAlertsForState = async (state: string) => {
  try {
    const response = await axios.get(`${baseUrl}/alerts/active/area/${state}`);
    return response.data;
  }
  catch (error) {
    console.error('Error fetching alert data', error);
    throw error;
  }
};

const getCurrentConditionsByForecastOffice = async (forecastOfficeID: string) => {
  try {
    const response = await axios.get(`${baseUrl}/stations/${forecastOfficeID}/observations`);
    return response.data;
  }
  catch (error) {
    console.error('Error fetching current condition data', error);
    throw error;
  }
}; 

export {
  getWeatherForecastURL,
  getWeatherForecast,
  getAlertsForState,
  getCurrentConditionsByForecastOffice
};

