import React from "react";
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-dark-teal/theme.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/GlobalComponents/Layout';
import Home from './pages/Home';
import TaskifyApp from './pages/TaskifyApp';
import WeatherApp from './pages/WeatherApp';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';
import LoansList from './pages/LoanTracker/LoansList';
import LoanProfilePage from "./pages/LoanTracker/LoanProfile";
import 'primeflex/primeflex.css';

function App() {

  return (
    <PrimeReactProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="taskify" element={<TaskifyApp />} />
          <Route path="weather" element={<WeatherApp />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="loans-list" element={<LoansList />} />
          <Route path="loan-profile" element={<LoanProfilePage />} />
        </Route>
      </Routes>
    </Router>
  </PrimeReactProvider>
  );
}

export default App;