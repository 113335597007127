import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { ButtonGroup } from 'primereact/buttongroup';
import { InputText } from 'primereact/inputtext';
import { alertApiResponse, alertFeature, coordinates, currentConditions, forecastPeriod } from '../types';
import { getCoordinatesFromCity } from '../api/geocodingService';
import { getWeatherForecast, getWeatherForecastURL, getAlertsForState, getCurrentConditionsByForecastOffice } from '../api';
import { ProgressSpinner } from 'primereact/progressspinner';
import TenDayForecast from '../components/WeatherComponents/TenDayForecast';
import StateDropdown from '../components/WeatherComponents/StateDropdown';
import { useToast } from '../context/ToastContext';
import Alerts from '../components/WeatherComponents/Alerts';
import CurrentConditions from '../components/WeatherComponents/CurrentConditions';

const WeatherApp: React.FC = () => {
  const [weatherSelection, setWeatherSelection] = useState("tenDayForecast");
  const [city, setCity] = useState("");
  const [selectedState, setSelectedState] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tenDayForecast, setTenDayForecast] = useState<forecastPeriod[] | null>(null);
  const [alerts, setAlerts] = useState<alertFeature[] | null>(null);
  const [currentConditionData, setCurrentConditionData] = useState<currentConditions[] | null>(null);
  const toast = useToast();

  if (!toast) {
    return null; // This ensures the context is available
  }

  const { showError, showSuccess } = toast;

  const fetchWeatherData = async (city: string, state: string) => {
    setLoading(true);
    try {
      //API calls
      const coordinates: coordinates = await getCoordinatesFromCity(city, state);
      const weatherURL: string = await getWeatherForecastURL(coordinates.latitude, coordinates.longitude);
      const alertsResponse: alertApiResponse = await getAlertsForState(state);
      const alertsData = alertsResponse.features;
      // //log
      console.log("url: ", weatherURL);
      console.log("alert data: ", alertsData);

      const urlSplit = weatherURL.split("/");
      const forecastOfficeID = urlSplit[4];
      console.log("forecastOfficeID: ", forecastOfficeID);
      const currentConditionData = await getCurrentConditionsByForecastOffice(forecastOfficeID);
      console.log("currentConditionData: ", currentConditionData);

      const forecastData = await getWeatherForecast(weatherURL);

      const processedData = forecastData.properties.periods.map((forecast: any) => {
        const imageUrl = forecast.icon;
        return {
          ...forecast,
          probabilityOfPrecipitation: {
            ...forecast.probabilityOfPrecipitation,
            value: forecast.probabilityOfPrecipitation?.value ?? 0,
          },
          imageUrl: imageUrl,
        };
      });

      setTenDayForecast(processedData);
      setAlerts(alertsData);
      setCurrentConditionData(currentConditionData);
      showSuccess('Weather data found!');

    } catch (error: unknown) {
      console.error('Error fetching weather data', error);
      showError(typeof error === 'string' ? error : (error instanceof Error ? error.message : 'Unknown error occurred'));

    } finally {
      setLoading(false); // Ensure loading is false after the API call, even if an error occurs
    }
  };

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (!city || !selectedState) {
      showError('City and State are required fields.');
      return;
    }
    fetchWeatherData(city, selectedState);
    setWeatherSelection('TenDayForecast');
  };

  const handleButtonClick = (selection: string) => {
    setWeatherSelection(selection);
  };

  return (
    <div className="weather-app">
      <div>
        <h1>Weather App</h1>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', gap: '20px', paddingTop: '20px' }}>
          <InputText
            type="text"
            placeholder="Enter city name"
            value={city}
            onChange={(event) => setCity(event.target.value)}
          />
          <StateDropdown
            selectedState={selectedState}
            handleChange={(e) => setSelectedState(e.value)}
          />
          <Button label="Submit" type="submit" />
        </form>
      </div>
      <div className="button-group">
        <ButtonGroup>
          <Button label="Alerts" icon="pi pi-clock" onClick={() => handleButtonClick("Alerts")} />
          <Button label="10 Day Forecast" icon="pi pi-calendar" onClick={() => handleButtonClick("TenDayForecast")} />
          <Button label="Current Conditions" icon="pi pi-times" onClick={() => handleButtonClick("CurrentConditions")} />
        </ButtonGroup>
      </div>
      <div className="weather-content">
        {loading ? (
          <ProgressSpinner />
        ) : (
          <>
            {weatherSelection === 'Alerts' && <Alerts data={alerts} />}
            {weatherSelection === "TenDayForecast" && <TenDayForecast data={tenDayForecast} />}
            {weatherSelection === 'CurrentConditions' && <CurrentConditions data={currentConditionData} />}
          </>
        )}
      </div>
    </div>
  );
};

export default WeatherApp;