import React, { useState } from 'react';
import { alertApiResponse, alertFeature } from '../../types';
import { Card } from 'primereact/card';

type AlertsProps = {
    data: alertFeature[] | null;
};

const Alerts: React.FC<AlertsProps> = ({ data }) => {
    const [title, setTitle] = useState<string>("");

    if (!data) {
        return <div>No alerts available.</div>;
    }




    return (


        //Original Unedited
    //   <div className=".alert-container">
    //     {data.map((alert, index) => (
    //       <div className="alert-card" key={index}>
    //         <Card 
    //             title={alert.properties.event} 
    //             subTitle={`Severity: ${alert.properties.severity}`} 
    //             footer={<span>{alert.properties.headline}</span>}>
    //           {/* <p>{alert.properties.description}</p> */}
    //         </Card>
    //       </div>
    //     ))}
    // </div>

    <div className="surface-0 p-4 shadow-2 border-round">
    {data.map((alert, index) => (
      <div className="alert-card" key={index}>
        <Card 
            title={alert.properties.event} 
            subTitle={`Severity: ${alert.properties.severity}`} 
            footer={<span>{alert.properties.headline}</span>}>
          {/* <p>{alert.properties.description}</p> */}
        </Card>
      </div>
    ))}
    </div>
      
    );
  };

export default Alerts;