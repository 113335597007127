import React from 'react';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';

const MenuBar: React.FC = () => {

 const items: MenuItem[] = [
        {
            label: "Home",
            command: () => { window.location.pathname = '/' }
        },    
        {
            label: "Taskify",
            command: () => { window.location.pathname = '/taskify' }
        },
        {
            label: "Weather",
            command: () => { window.location.pathname = '/weather' }
        },
        {
            label: "About",
            command: () => { window.location.pathname = '/about' }
        },
        {
            label: "Contact",
            command: () => { window.location.pathname = '/contact' }
        },
        {
            label: "Loan Tracker",
            command: () => { window.location.pathname = '/loans-list' }
        }
    ]

    return (
        <Menubar model={items}  />
    );
};

export default MenuBar;
