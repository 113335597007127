import React from "react";
import { Button } from "primereact/button";
import BlockViewer from "../../primeblocks/demo/components/BlockViewer";

const LoanProfilePage: React.FC = () => {
   
    return (
        <>
            <BlockViewer containerClassName="px-4 py-8 md:px-6 lg:px-8" >
                <div className="surface-card p-4 shadow-2 border-round">
                    <div className="font-medium text-3xl text-900 mb-3">Loan Profile</div>

                    {/* Details */}
                    <div className="text-500 mb-5 pl-3">Paid in full</div>
                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">Name</div>
                            <div className="text-900 pl-3">Cole Lenard</div>
                        </div>
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">Address</div>
                            <div className="text-900 pl-3">12345 N Fake Street</div>
                        </div>
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">Loan Amount</div>
                            <div className="text-900 pl-3">$85,000</div>
                        </div>
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">On Time Payment Amount</div>
                            <div className="text-900 pl-3">$991.67</div>
                        </div>
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">Loan Begin Date</div>
                            <div className="text-900 pl-3">8/24/2024</div>
                        </div>
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">Late Fee</div>
                            <div className="text-900 pl-3">$49.58</div>
                        </div>
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">Paid Through</div>
                            <div className="text-900 pl-3">9/24/2024</div>
                        </div>
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">Late Payment Total Amount</div>
                            <div className="text-900 pl-3">$1,041.25</div>
                        </div>

                        {/* Notes */}
                        <div className="col-12 p-3 border-top-1 border-bottom-1 surface-border">
                            <div className="text-500 font-medium mb-2">Notes</div>
                            <div className="text-900 line-height-3 pl-3">Paid late 4 times already. I promised him that I would not renew. You can really type anything into these notes that 
                                                                    you may want to know about the loan or the client.  And as you can see the comment wraps when it hits the end as well.
                            </div>
                        </div>

                        {/* Payment History */}
                        <div className="col-12 p-3">
                            <div className="text-500 font-medium mb-3">Payment History</div>

                            {/* Payment History Entry 1 */}
                            <div className="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row">
                                {/* Payment Amount */}
                                <div className="flex flex-column p-3 h-full">
                                    <div className="text-600 font-medium mb-2 col-12">Payment Amount</div>
                                    <div className="text-600 pl-6">$991.67</div>
                                </div>

                                {/* New Payment Date Column */}
                                <div className="flex flex-column p-3 h-full">
                                    <div className="text-600 font-medium mb-2 col-12">Payment Date</div>
                                    <div className="text-600 pl-6">10/15/2024</div>
                                </div>

                                {/* Edit Button */}
                                <Button label="Edit" className="p-button-outlined mt-2 md:mt-0" />
                            </div>

                            {/* Payment History Entry 2 */}
                            <div className="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row">
                                {/* Payment Amount */}
                                <div className="flex flex-column p-3 h-full">
                                    <div className="text-600 font-medium mb-2 col-12">Payment Amount</div>
                                    <div className="text-600 pl-6">$991.67</div>
                                </div>

                                {/* New Payment Date Column */}
                                <div className="flex flex-column p-3 h-full">
                                    <div className="text-600 font-medium mb-2 col-12">Payment Date</div>
                                    <div className="text-600 pl-6">11/15/2024</div>
                                </div>

                                {/* Edit Button */}
                                <Button label="Edit" className="p-button-outlined mt-2 md:mt-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </BlockViewer > 
        </>
    );
};

export default LoanProfilePage;