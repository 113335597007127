import React from 'react';
import { currentConditions } from "../../types";

type CurrentConditionsProps = {
    data: currentConditions[] | null;
  };
  
  const CurrentConditions: React.FC<CurrentConditionsProps> = ({ data }) => {
  
    return (
      <div>
        {data && (
          'You|ve got data!'
        )}
      </div>
    );
  };
  
  export default CurrentConditions;