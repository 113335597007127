import  React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';



const Home = () => {
    return (
        <>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <h1>Hello World, welcome to my portfolio!</h1>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <h1>Use the links at the top of the page to view my various projects.</h1>
        </div>
        </>
    )
};

export default Home;