import { Button } from 'primereact/button';
import React from 'react';
import { ListRowProps } from '../../types';
import { Link } from 'react-router-dom';
  
  const ListRow: React.FC<ListRowProps> = ({ name, address, paymentAmount, paymentDueDate }) => {

    const formattedPaymentDueDate = "Payment due: " + paymentDueDate.toLocaleDateString();
    const formattedPaymentAmount = "$" + paymentAmount;
  
    return (


        <div className="flex align-items-center py-3 px-2 border-top-1 border-300 flex-wrap"
            style={{ minWidth: '1000px' }}>

        {/* Container for name and address */}
        <div className="flex flex-column p-3 h-full" style={{ flexGrow: 1, borderRadius: '6px' }}>
          <div className="text-900 font-medium text-xl mb-2">{name}</div>
          <div className="text-600 pl-3">{address}</div>
        </div>

        {/* Container for name payment amount and due date */}
        <div className="flex flex-column p-3 h-full" style={{ flexGrow: 1, borderRadius: '6px' }}>
          <div className="text-900 font-medium text-xl mb-2">{formattedPaymentAmount}</div>
          <div className="text-600 pl-3">{formattedPaymentDueDate}</div>
        </div>
  
        {/* Container for Edit button, flex-shrink-0 ensures it doesn't shrink */}
        <div className="ml-auto flex-shrink-0">
          <Link to={`/loan-profile`} style={{ textDecoration: 'none' }}>
            <Button label="Edit" className="p-button-outlined mt-2 md:mt-0t" />
          </Link>
        </div>
        
      </div>
    );
  };
  
  export default ListRow;